import { posthogCaptureWithUserContext } from "./general";
import {
  getCoursePropertiesForEvent,
  getCourseUnitPropertiesForEvent,
  getResourceFilePropertiesForEvent,
  getResourcePropertiesForEvent,
} from "./properties";

/**
 * Captures the event the user selects a group of one or more
 * resources to view on the downloadables page.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {string} properties.groupType The type of group selected ("resource", "unit" or "node").
 * @param {Parameters<typeof getResourcePropertiesForEvent>[0] | undefined} properties.resource The resource object, if applicable.
 * @param {Parameters<typeof getCourseUnitPropertiesForEvent>[0] | undefined} properties.unit The course unit object, if applicable.
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0] | undefined} properties.course The course object, if applicable.
 * @param {{ noid: number, name: string } | undefined} properties.node The topic tree node object, if applicable.
 * @param {{ query: string, results: number, position: number } | undefined} properties.search Details of search, if applicable.
 */
export function posthogEventResourceGroupSelected({
  triggerId,
  groupType,
  resource,
  unit,
  course,
  node,
  search,
}) {
  const resourceProperties = resource
    ? getResourcePropertiesForEvent(resource)
    : {};
  const unitProperties = unit ? getCourseUnitPropertiesForEvent(unit) : {};
  const courseProperties = course ? getCoursePropertiesForEvent(course) : {};
  const nodeProperties = node
    ? { resource_node_id: node.noid, resource_node_name: node.name }
    : {};
  const searchProperties = search
    ? {
        resource_search_query: search.query,
        resource_search_results: search.results,
        resource_search_position: search.position,
      }
    : {};
  posthogCaptureWithUserContext("resource_group_selected", {
    trigger_id: triggerId,
    resource_group_type: groupType,
    ...resourceProperties,
    ...unitProperties,
    ...courseProperties,
    ...nodeProperties,
    ...searchProperties,
  });
}

/**
 * Captures the event the user downloads a resource file.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getResourcePropertiesForEvent>[0]} properties.resource The resource object.
 * @param {Parameters<typeof getResourceFilePropertiesForEvent>[0]} properties.file The resource file object.
 * @param {number} properties.results The number of resources on the page the file was downloaded from.
 * @param {number} properties.position The position of the file in the list of resources on the page
 */
export function posthogEventResourceFileDownloaded({
  triggerId,
  resource,
  file,
  results,
  position,
}) {
  const resourceProperties = resource ? getResourcePropertiesForEvent(resource) : {};
  const fileProperties = file ? getResourceFilePropertiesForEvent(file) : {};
  posthogCaptureWithUserContext("resource_file_downloaded", {
    trigger_id: triggerId,
    ...resourceProperties,
    ...fileProperties,
    resources_on_page: results,
    resource_position: position,
  });
}
