import posthog from "posthog-js";
import { getUserPropertiesForEvent } from "./properties";

/**
 * Attaches user context to an event and captures it with PostHog.
 *
 * @param {string} eventName
 * @param {Object?} properties
 */
export function posthogCaptureWithUserContext(eventName, properties = {}) {
  posthog.capture(eventName, {
    ...getUserPropertiesForEvent(),
    ...properties,
  });
}

/**
 * Captures a pageview event.
 */
export function posthogPageviewEvent() {
  posthogCaptureWithUserContext("$pageview");
}
