import { dfmAlert } from "~/public_html/dfm5";

export async function getDownloadablesForCourseUnit(cuid) {
  try {
    const response = await fetch(`/api/downloadables/byunit/${cuid}`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const downloadables = await response.json();
    return downloadables;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving resources for this unit.");
  }
}
