import $ from "jquery";

window.$ = window.jQuery = $;

// ----------------------------------------------------------------
// -- CANVAS                                            -----------
// ----------------------------------------------------------------
var canvasMouseDown = true;

var highlightCanvasTimer;
var finishingDrawing;

function canvasMessage(canvas, message) {
   canvas.parent().find('.canvas-message').remove();
   canvas.before("<div class='canvas-message' style='height:31px'>"+message+"</div>");
   canvas.prev().show();
}

jQuery.fn.dfmCanvas = function(arg1, arg2) {
   var canvas = $(this);
   // ----------------------------------------
   // -- To 'start drawing', put canvas in highlight mode and create temporary shape placeholder.
   // ----------------------------------------
   if(arg1=="startDrawing") {
      if(canvas.data("readOnly"))return;
      canvas.addClass("drawMode");
      if(canvas.data("editMode")) {
         canvas.addClass("highlight");
         highlightCanvasTimer = setInterval(function(){ canvas.toggleClass("highlight") },1000);
      }

      if(!canvas.data("readOnly")) {
         if(arg2==="multiline") {
            canvasMessage(canvas, "Click/Press each point of your line.");
         } else if(arg2=="rectangle") {
            canvasMessage(canvas, "Drag across the screen to draw your rectangle.");
         } else if(arg2==="line") {
            canvasMessage(canvas, "Drag across the screen to draw your line.");
         } else if(arg2==="polygon") {
            canvasMessage(canvas, "Click/Press each point of your polygon.");
         }
      }

      canvas.data("tempShape", {points:[], type:arg2, v: 2});
      if(!canvas.data("editMode"))canvas.removeData("answerShape");
      canvas.dfmCanvas("redraw");
   }
   else if(arg1=="finishDrawing") {
      if(canvas.data("readOnly"))return;
      finishingDrawing = true;
      if(canvas.data("editMode")) {
         dfmDialog(
            "Do you want this shape you've just drawn to be the answer shape? (i.e. that students have to draw)",
            [{label: "Yes", action: function(){ 
                  canvas.data("answerShape", canvas.data("tempShape")); 
                  canvas.data("type", canvas.data("tempShape").type); 
                  canvas.removeData("tempShape");
                  finishingDrawing = false;
                  canvas.dfmCanvas("redraw");
                  notifyCanvasListeners(canvas);
             }},
             {label: "No", action: function(){ 
                  canvas.data("nonAnswerShapes").push(canvas.data("tempShape"));
                  canvas.removeData("tempShape");
                  finishingDrawing = false;
                  canvas.dfmCanvas("redraw");
                  notifyCanvasListeners(canvas);
             }}] 
         );

         canvas.removeClass("drawMode");
         canvas.removeClass("highlight");
         clearInterval(highlightCanvasTimer);
         notifyCanvasListeners(canvas);
         canvasMessage(canvas, "You can delete shapes by clicking on them.");
      }
      else {
         // If doing the question normally, the shape just drawn is their answer.
         canvas.data("answerShape", canvas.data("tempShape"));
         canvas.removeData("tempShape");
         finishingDrawing = false;
         canvas.dfmCanvas("redraw");
         notifyCanvasListeners(canvas);
         canvasMessage(canvas, "You may now redraw your shape if you wish.");
      }

      
   }
   else if(arg1=="cancelDrawing") {
      if(finishingDrawing)return; // don't cancel until finish drawing process has completed (e.g. responding to dialog)
      canvas.removeClass("drawMode");
      if(canvas.data("editMode")) {
         canvas.removeClass("highlight");
         clearInterval(highlightCanvasTimer);
         canvas.removeData("tempShape");
         canvas.dfmCanvas("redraw");
         notifyCanvasListeners(canvas);
      }
   }
   else if(arg1=="redraw") {
      if(canvas.is(":visible"))dfmDraw(canvas);
   }
   else if(arg1=="setNonAnswerShapes") {
      canvas.data("nonAnswerShapes",arg2);
      canvas.dfmCanvas("redraw");
   }
   else if(arg1=="setAnswerShape") {
      canvas.data("answerShape",arg2);
      canvas.dfmCanvas("redraw");
   }
   else if(arg1=="setGrid") {
      arg2.v = 2; // version 2
      canvas.data("grid",arg2);
      canvas.dfmCanvas("redraw");
      notifyCanvasListeners(canvas);
   }
   else if(arg1=="getAnswerData") {
      var answerData = {data:{}};
      if(canvas.data("answerShape"))answerData.correctAnswer = canvas.data("answerShape");
      if(canvas.data("nonAnswerShapes"))answerData.data.nonAnswerShapes = canvas.data("nonAnswerShapes");
      if(canvas.data("grid"))answerData.data.grid = canvas.data("grid");
      answerData.data.type = canvas.data("type"); 
      return answerData;
   }

   // ----------------------------------------
   // -- CONSTRUCTOR
   // ----------------------------------------
   else if(!arg2) {
      // Constructor
      var options = arg1;

      canvas.data("nonAnswerShapes",[]);
      canvas.removeData("answerShape");
      canvas.addClass('dfmCanvas');

      if(options.answerData && options.answerData.grid && options.answerData.grid.v != 2) {
         var transformedNonAnswerPoints = [];
         if(options.answerData.nonAnswerShapes) {
            for(var i=0; i<options.answerData.nonAnswerShapes.length; i++) {
               for(var j=0; j<options.answerData.nonAnswerShapes[i].points.length; j++) {
                  options.answerData.nonAnswerShapes[i].points[j] = mapPixelToCoordinate(options.answerData.nonAnswerShapes[i].points[j], options.answerData.grid);
                  // console.log("Coordinate updated to "+JSON.stringify(options.answerData.nonAnswerShapes[i].points[j]));
               }
            }
         }
         options.answerData.grid.v = 2;
      }

      if(options.answer && options.answer.v != 2) {
         if(options.answer.points)for(var j=0; j<options.answer.points.length; j++) {
            options.answer.points[j] = mapPixelToCoordinate(options.answer.points[j], options.answerData.grid);
         }
         options.answer.v = 2;
      }


      if(options.answerData) {
         if(options.answerData.nonAnswerShapes)canvas.data("nonAnswerShapes", options.answerData.nonAnswerShapes);
         if(options.answerData.grid) {
            options.answerData.v = 2; // version 2
            canvas.data("grid", options.answerData.grid);
         }
         canvas.data("type", options.answerData.type);
      }

      var bWidth = parseInt(canvas.css("borderLeftWidth"),10);

      if(options.listeners)canvas.data("listeners", options.listeners);
      if(options.editMode)canvas.data("editMode", true);
      else canvas.data("editMode", false);
      if(options.readOnly)canvas.data("readOnly", true);
      else canvas.data("readOnly", false);
      if(canvas.data("editMode")) {
         canvas.parent().find('.dfm-canvas-buttons').remove();
         canvas.before("<div class='dfm-canvas-buttons'><input type='button' value='Insert Grid'> <input type='button' value='Draw Shape'></div>");
         addGridButtonDialog(canvas.prev().children(":nth-child(1)"), canvas);
         addShapeSelectorDialog(canvas.prev().children(":nth-child(2)"), canvas);
      }
      if(options.answer) {
         canvas.data("answerShape", options.answer);
         if(canvas.data("editMode")) {
            canvasMessage(canvas, "You can delete shapes by clicking on them.");
         }
      }

      // if(canvas.attr('id')=="solution-canvas") {
      //    console.log("SOLUTIONCANVAS READONLY="+JSON.stringify(canvas.data("readOnly")));
      // }

      if(!options.readOnly) {
         if(options.editMode)canvasMessage(canvas, "Use the buttons above to draw answer/non-answer shapes and to add a grid.");
         else canvasMessage(canvas, "Draw your shape in the space below.");
      }


      // ----------------------------------------
      // -- Respond to CLICKS on the canvas
      // ----------------------------------------

      var apple = null!==navigator.userAgent.match(/(iPad|iPhone|iPod)/i);
      var android = null!==navigator.userAgent.match(/Android/i);
      var isTouch = apple||android;

      if(!canvas.data("readOnly") && (!isTouch || (canvas.data("type")!=="line" && canvas.data("type")!=="rectangle")))canvas.unbind("mousedown").mousedown(function(e){
         canvasMouseDown = true;
         // var x = ($(window).width() / $(document).width()) * (e.clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         // var y = ($(window).width() / $(document).width()) * (e.clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var x = 1 * (e.clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         var y = 1 * (e.clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var p = {x:x, y:y};
         // console.log("SF: "+($(window).width() / $(document).width()));
         // console.log("EPAGEY "+e.pageY+" SCROLLTOP: "+$(window).scrollTop()+" OFFSETTOP "+$(this).offset().top+" BWIDTH: "+bWidth+" THISHEIGHT "+$(this).height());

         p = snapPoint(p, canvas);
         p = mapPixelToCoordinate(p, canvas.data("grid"));
         console.log("P: "+JSON.stringify(p));

         // Has student started drawing a replacement shape?
         if(!canvas.data("tempShape") && !canvas.data("editMode") && canvas.data("answerShape")) {
            canvas.removeData("answerShape");
            canvas.data("tempShape", {points:[],type:canvas.data("type")});
            canvas.dfmCanvas("redraw");
            notifyCanvasListeners(canvas); 
         }

         // Clicking while not drawing a shape deletes - providing we click the shape.
         if(!canvas.data("tempShape") && canvas.data("editMode")) {
            
            var delK = -1;
            $.each(canvas.data("nonAnswerShapes"), function(k,v){
               if(didPointSelectShape(p, v, canvas))delK = k;
            });
            if(delK>=0) {
               canvas.data("nonAnswerShapes").splice(delK,1);
               canvas.dfmCanvas("redraw");
               notifyCanvasListeners(canvas);
            }
            if(canvas.data("answerShape") && didPointSelectShape(p, canvas.data("answerShape"), canvas)){
               canvas.removeData("answerShape");
               canvas.dfmCanvas("redraw");
               notifyCanvasListeners(canvas); 
            }
            return;
         }
         if(!canvas.data("tempShape"))return;

         var polygonDrawMode = canvas.data("tempShape").type;
         var polygonPoints = canvas.data("tempShape").points;
         if(polygonDrawMode==="polygon") {
            if(polygonPoints.length>=1 && arePointsClose(p,polygonPoints[0], canvas)) {
               canvas.data("tempShape").points.push(polygonPoints[0]);
               canvas.dfmCanvas("finishDrawing");
               return;
            }
         }


         var sameAsLast = false;
         if(polygonPoints.length>=1)sameAsLast = arePointsClose(p,polygonPoints[polygonPoints.length-1], canvas);
         if(!sameAsLast) {
            canvas.data("tempShape").points.push(p);
            // console.log("Added "+x+","+y);
         } else if(polygonDrawMode!=="polygon") {
            // Probably a double click if the same point.
            canvas.dfmCanvas("finishDrawing");
         }

         canvas.dfmCanvas("redraw");
         if(polygonDrawMode==="line" && polygonPoints.length==2)canvas.dfmCanvas("finishDrawing");
         if(polygonDrawMode==="rectangle" && polygonPoints.length==2) {
            var x1 = Math.min(polygonPoints[0].x, polygonPoints[1].x);
            var x2 = Math.max(polygonPoints[0].x, polygonPoints[1].x);
            var y1 = Math.min(polygonPoints[0].y, polygonPoints[1].y);
            var y2 = Math.max(polygonPoints[0].y, polygonPoints[1].y);
            canvas.data("tempShape").points = [{x: x1, y:y1}, {x:x2, y:y2}];
            canvas.dfmCanvas("finishDrawing");
         }
         if(polygonDrawMode==="point" && polygonPoints.length==1)canvas.dfmCanvas("finishDrawing");


         if(polygonDrawMode==="multiline" && polygonPoints.length==1) {
            canvasMessage(canvas, "<button id='cancel-button' class='medium-black-button'>Cancel</button> <button id='finish-button' class='medium-black-button'>Finish Drawing</button>");
            canvas.prev().find('#finish-button').click(function(){
               canvas.data("tempShape").tempPoint = undefined;
               canvas.dfmCanvas("finishDrawing");
            });
            canvas.prev().find('#cancel-button').click(function(e2){
               e2.preventDefault();
               canvas.dfmCanvas("startDrawing", polygonDrawMode);
            });
         }
         if(polygonDrawMode==="polygon" && polygonPoints.length==1) {
            canvasMessage(canvas, "<button id='cancel-button' class='medium-black-button'>Cancel</button>");
            canvas.prev().find('#cancel-button').click(function(e2){
               e2.preventDefault();
               canvas.dfmCanvas("startDrawing", polygonDrawMode);
            });
         }

      });

// ------------------------------
// TOUCH
      if(!canvas.data("readOnly") && isTouch && (canvas.data("type")=="line" || canvas.data("type")=="rectangle"))canvas[0].addEventListener('touchstart', function(e){
         e.preventDefault();
         canvasMouseDown = true;
         var touches = e.changedTouches;
         // var x = ($(window).width() / $(document).width()) * (touches[0].clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         // var y = ($(window).width() / $(document).width()) * (touches[0].clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var x = 1 * (touches[0].clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         var y = 1 * (touches[0].clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var p = {x:x, y:y};

         p = snapPoint(p, canvas);
         p = mapPixelToCoordinate(p, canvas.data("grid"));

         // Has student started drawing a replacement shape?
         if(!canvas.data("tempShape") && !canvas.data("editMode") && canvas.data("answerShape")) {
            canvas.removeData("answerShape");
            canvas.data("tempShape", {points:[],type:canvas.data("type")});
            canvas.dfmCanvas("redraw");
            notifyCanvasListeners(canvas); 
         }

         // Clicking while not drawing a shape deletes - providing we click the shape.
         if(!canvas.data("tempShape") && canvas.data("editMode")) {
            
            var delK = -1;
            $.each(canvas.data("nonAnswerShapes"), function(k,v){
               if(didPointSelectShape(p, v, canvas))delK = k;
            });
            if(delK>=0) {
               canvas.data("nonAnswerShapes").splice(delK,1);
               canvas.dfmCanvas("redraw");
               notifyCanvasListeners(canvas);
            }
            if(canvas.data("answerShape") && didPointSelectShape(p, canvas.data("answerShape"), canvas)){
               canvas.removeData("answerShape");
               canvas.dfmCanvas("redraw");
               notifyCanvasListeners(canvas); 
            }
            return;
         }
         if(!canvas.data("tempShape"))return;

         var polygonDrawMode = canvas.data("tempShape").type;
         var polygonPoints = canvas.data("tempShape").points;
         if(polygonDrawMode==="polygon") {
            if(polygonPoints.length>=1 && arePointsClose(p,polygonPoints[0], canvas)) {
               canvas.data("tempShape").points.push(polygonPoints[0]);
               canvas.dfmCanvas("finishDrawing");
               return;
            }
         }

         var sameAsLast = false;
         if(polygonPoints.length>=1)sameAsLast = arePointsClose(p,polygonPoints[polygonPoints.length-1], canvas);
         if(!sameAsLast) {
            canvas.data("tempShape").points.push(p);
            // console.log("Added "+x+","+y);
         } else if(polygonDrawMode!=="polygon") {
            // Probably a double click if the same point.
            canvas.dfmCanvas("finishDrawing");
         }

         canvas.dfmCanvas("redraw");
         if(polygonDrawMode==="line" && polygonPoints.length==2)canvas.dfmCanvas("finishDrawing");
         if(polygonDrawMode==="rectangle" && polygonPoints.length==2) {
            var x1 = Math.min(polygonPoints[0].x, polygonPoints[1].x);
            var x2 = Math.max(polygonPoints[0].x, polygonPoints[1].x);
            var y1 = Math.min(polygonPoints[0].y, polygonPoints[1].y);
            var y2 = Math.max(polygonPoints[0].y, polygonPoints[1].y);
            canvas.data("tempShape").points = [{x: x1, y:y1}, {x:x2, y:y2}];
            canvas.dfmCanvas("finishDrawing");
         }
         if(polygonDrawMode==="point" && polygonPoints.length==1)canvas.dfmCanvas("finishDrawing");

         if(polygonDrawMode==="multiline" && polygonPoints.length==1) {
            canvasMessage(canvas, "<button id='cancel-button' class='medium-black-button'>Cancel Drawing</button> <button id='finish-button' class='medium-black-button'>Finish Drawing</button>");
            canvas.prev().find('#finish-button').click(function(){
               canvas.data("tempShape").tempPoint = undefined;
               canvas.dfmCanvas("finishDrawing");
            });
            canvas.prev().find('#cancel-button').click(function(e2){
               e2.preventDefault();
               canvas.dfmCanvas("startDrawing", polygonDrawMode);
            });
         }
         if(polygonDrawMode==="polygon" && polygonPoints.length==1) {
            canvasMessage(canvas, "<button id='cancel-button' class='medium-black-button'>Cancel Drawing</button>");
            canvas.prev().find('#cancel-button').click(function(e2){
               e2.preventDefault();
               canvas.dfmCanvas("startDrawing", polygonDrawMode);
            });
         }

      }, false);

      if(!canvas.data("readOnly") && isTouch && (canvas.data("type")==="line" || canvas.data("type")==="rectangle"))canvas[0].addEventListener('touchmove', function(e){
         e.preventDefault();
         var touches = e.changedTouches;
         if(!canvas.data("tempShape"))return;
         var polygonDrawMode = canvas.data("tempShape").type;
         // var x = ($(window).width() / $(document).width()) * (touches[0].clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         // var y = ($(window).width() / $(document).width()) * (touches[0].clientY + $(window).scrollTop()- $(this).offset().top - bWidth)/$(this).height();

         var x = (1) * (touches[0].clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         var y = (1) * (touches[0].clientY + $(window).scrollTop()- $(this).offset().top - bWidth)/$(this).height();

         canvas.data("tempShape").tempPoint =  mapPixelToCoordinate(snapPoint({x:x, y:y}, canvas), canvas.data("grid"));
         // $("#question-info-box").html("DRAGGING TEST "+touches[0].clientX+" "+touches[0].clientY);
         canvas.dfmCanvas("redraw");
      }, false);

      if(!canvas.data("readOnly") && isTouch && (canvas.data("type")==="line" || canvas.data("type")==="rectangle"))canvas[0].addEventListener('touchend', function(e){
         e.preventDefault();
         canvasMouseDown = false;
         var touches = e.changedTouches;
         // var x = ($(window).width() / $(document).width()) * (touches[0].clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         // var y = ($(window).width() / $(document).width()) * (touches[0].clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var x = 1 * (touches[0].clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         var y = 1 * (touches[0].clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var p = mapPixelToCoordinate(snapPoint({x:x, y:y}, canvas), canvas.data("grid"));
         var polygonPoints = canvas.data("tempShape").points;
         if(arePointsClose(p,polygonPoints[0], canvas))return;
         canvas.data("tempShape").points.push(p);
         if(polygonPoints.length==2) {
            if(canvas.data("type")==="rectangle") {
               var x1 = Math.min(polygonPoints[0].x, polygonPoints[1].x);
               var x2 = Math.max(polygonPoints[0].x, polygonPoints[1].x);
               var y1 = Math.min(polygonPoints[0].y, polygonPoints[1].y);
               var y2 = Math.max(polygonPoints[0].y, polygonPoints[1].y);
               canvas.data("tempShape").points = [{x: x1, y:y1}, {x:x2, y:y2}];
            }
            canvas.dfmCanvas("finishDrawing");
         }
      }, false);

// ----------------------------------


      // Only applicable if a rectangle or single line.
      canvas.mouseup(function(e){
         e.preventDefault();
         canvasMouseDown = false;
         if(!canvas.data("tempShape") || (canvas.data("tempShape").type!="rectangle" && canvas.data("tempShape").type!="line"))return;
         // var x = ($(window).width() / $(document).width()) * (e.clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         // var y = ($(window).width() / $(document).width()) * (e.clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var x = 1 * (e.clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         var y = 1 * (e.clientY + $(window).scrollTop() - $(this).offset().top - bWidth)/$(this).height();

         var p = {x:x, y:y};
         p = snapPoint(p, canvas);
         p = mapPixelToCoordinate(p, canvas.data("grid"));
         var polygonPoints = canvas.data("tempShape").points;
         if(arePointsClose(p,polygonPoints[0], canvas))return;
         canvas.data("tempShape").points.push(p);
         if(polygonPoints.length==2) {
            if(canvas.data("tempShape").type=="rectangle") {
               var x1 = Math.min(polygonPoints[0].x, polygonPoints[1].x);
               var x2 = Math.max(polygonPoints[0].x, polygonPoints[1].x);
               var y1 = Math.min(polygonPoints[0].y, polygonPoints[1].y);
               var y2 = Math.max(polygonPoints[0].y, polygonPoints[1].y);
               canvas.data("tempShape").points = [{x: x1, y:y1}, {x:x2, y:y2}];
            }
            canvas.dfmCanvas("finishDrawing");
         }
      });

      if(!canvas.data("readOnly"))canvas.mousemove(function(e) {
         if(!canvas.data("tempShape"))return;
         var polygonDrawMode = canvas.data("tempShape").type;
         // var x = ($(window).width() / $(document).width()) * (e.clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         // var y = ($(window).width() / $(document).width()) * (e.clientY + $(window).scrollTop()- $(this).offset().top - bWidth)/$(this).height();

         var x = 1 * (e.clientX + $(window).scrollLeft() - $(this).offset().left - bWidth)/$(this).width();
         var y = 1 * (e.clientY + $(window).scrollTop()- $(this).offset().top - bWidth)/$(this).height();

         canvas.data("tempShape").tempPoint = mapPixelToCoordinate(snapPoint({x:x, y:y}, canvas), canvas.data("grid"));
         canvas.dfmCanvas("redraw");
      });


      if(!canvas.data("readOnly"))canvas.mouseout(function(e) {
         if(canvas.data("tempShape"))canvas.data("tempShape").tempPoint = undefined;
         canvas.dfmCanvas("redraw");
      });

      canvas.dfmCanvas("redraw");
      resizeCanvas();


      // If a student, let them start drawing straight away
      if(!canvas.data("editMode") && !canvas.data("readOnly"))canvas.dfmCanvas("startDrawing", canvas.data("type"));

   }
}



function mapCoordinateToPixel(p, grid) {
   if(!p)return p;
   var xDiff = grid.xaxis.to - grid.xaxis.from;
   var yDiff = grid.yaxis.to - grid.yaxis.from;
   if(grid.mode==="axis") {
      var xNew = (p.x - grid.xaxis.from) / xDiff;
      var yNew = 1 - (p.y - grid.yaxis.from) / yDiff;
      return {x: xNew, y: yNew };
   } else if(grid.mode==="graph") {
      var isCategorical = grid.xaxis.values!=undefined && $.trim(grid.xaxis.values)!="";
      var cvalues;
      if(isCategorical)cvalues = grid.xaxis.values.split(",");
      // If categorical, in x-direction, coordinate of 1 is rightmost point of bar chart, and 0 is y-axis.
      var xNew = isCategorical ? (xGraphMarginLeft + p.x * (1 - xGraphMarginLeft - xGraphMarginRight))
         : (xGraphMarginLeft + ((p.x - grid.xaxis.from) / xDiff) * (1 - xGraphMarginLeft - xGraphMarginRight));
      var yNew = 1 - (yGraphMarginBottom + ((p.y - grid.yaxis.from) / yDiff) * (1 - yGraphMarginTop - yGraphMarginBottom));
      return {x: xNew, y: yNew };
   }
}

// Note that (1,1) is bottom-right of canvas.
function mapPixelToCoordinate(p, grid) {
   var xDiff = grid.xaxis.to - grid.xaxis.from;
   var yDiff = grid.yaxis.to - grid.yaxis.from;
   if(grid.mode==="axis") {
      var xNew = parseFloat(grid.xaxis.from) + (p.x * xDiff);
      var yNew = parseFloat(grid.yaxis.from) + ((1-p.y) * yDiff);
      // console.log("pX: "+p.x+" xDiff: "+xDiff+" gridFrom: "+grid.xaxis.from+" xNew: "+xNew);
      return {x: xNew, y: yNew };
   } else if(grid.mode==="graph") {
      var isCategorical = grid.xaxis.values!=undefined && $.trim(grid.xaxis.values)!="";
      var cvalues;
      if(isCategorical)cvalues = grid.xaxis.values.split(",");
      var xNew = isCategorical ? (p.x - xGraphMarginLeft) / (1 - xGraphMarginLeft - xGraphMarginRight)
         : parseFloat(grid.xaxis.from) + xDiff * (p.x - xGraphMarginLeft) / (1 - xGraphMarginLeft - xGraphMarginRight);
      var yNew = parseFloat(grid.yaxis.from) + yDiff * ((1-p.y) - yGraphMarginBottom) / (1 - yGraphMarginTop - yGraphMarginBottom);
      return {x: xNew, y: yNew };

   }
}


function snapPoint(p, canvas) {
   if(!p || !p.x || !p.y)return p;
   var g = canvas.data("grid");
   if(g && g.mode=="axis") {
      var gridSizeX = 1/(g.xaxis.to - g.xaxis.from);
      var gridSizeY = 1/(g.yaxis.to - g.yaxis.from);
      var numXGrids = p.x / gridSizeX;
      var numYGrids = p.y / gridSizeY;
      numXGrids = Math.round(numXGrids * 2) / 2.0;
      numYGrids = Math.round(numYGrids * 2) / 2.0;
      return {x: numXGrids*gridSizeX, y: numYGrids*gridSizeY };
   }
   return p;
}

// Determines whether a click is within the confines (or in close proximity) to a shape.
function didPointSelectShape(p, shape, canvas) {
   if(shape.type=="polygon")return isInsidePolygon(p, shape.points);
   if(shape.type=="point")return arePointsClose(p, shape.points[0], canvas);
   if(shape.type=="line" || shape.type=="multiline") {
      for(var i=0; i<shape.points.length-1; i++) {
         if(distanceFromPointToLineSegment(p, shape.points[i], shape.points[i+1])<7)return true;
      }
      return false;
   }
   if(shape.type=="rectangle") {
      return p.x >= shape.points[0].x && p.x <= shape.points[1].x 
          && p.y >= shape.points[0].y && p.y <= shape.points[1].y;
   }
   return false;
}

export function distanceFromPointToLineSegment(p, p1, p2) {
  var x = p.x; var y = p.y;
  var x1 = p1.x; var y1 = p1.y;
  var x2 = p2.x; var y2 = p2.y;
  var A = x - x1;
  var B = y - y1;
  var C = x2 - x1;
  var D = y2 - y1;

  var dot = A * C + B * D;
  var len_sq = C * C + D * D;
  var param = -1;
  if (len_sq != 0) //in case of 0 length line
      param = dot / len_sq;
  var xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  }
  else if (param > 1) {
    xx = x2;
    yy = y2;
  }
  else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }
  var dx = x - xx;
  var dy = y - yy;
  return Math.sqrt(dx * dx + dy * dy);
}



var xGraphGrids = 12.0;
var yGraphGrids = 8.0;
var xGraphMarginLeft = 0.08;
var xGraphMarginRight = 0.08;
var yGraphMarginBottom = 0.12;
var yGraphMarginTop = 0.04; 

function dfmDraw(canvas) {

   var w = canvas.width();
   var h = canvas.height();
   var ctx = canvas[0].getContext("2d");
   ctx.clearRect(0, 0, w, h);
   // White background.
   ctx.fillStyle = "#ffffff";
   ctx.fillRect(0, 0, w, h);

   if(canvas.data("grid")) {
      var gridOptions = canvas.data("grid");
      // console.log(JSON.stringify(gridOptions));

      if(gridOptions.mode=="axis") {

         // Draw grid lines.
         ctx.beginPath(); 
         ctx.strokeStyle = gridOptions.color ? gridOptions.color : "#bbbbbb";
         ctx.lineWidth = 1;
         var numX = gridOptions.xaxis.to - gridOptions.xaxis.from;
         var numY = gridOptions.yaxis.to - gridOptions.yaxis.from;
         var gridWidth = w/numX;
         var gridHeight = h/numY;
         var i=0; while(i<w) { // vertical lines
            ctx.moveTo(i, 0);
            ctx.lineTo(i, h);
            i+= gridWidth;
         } 
         var i=h; while(i>=0) { // horizontal lines
            ctx.moveTo(0, i);
            ctx.lineTo(w, i);
            i-= gridHeight;
         }    
         ctx.stroke();

         // Draw axis.
         ctx.beginPath(); 
         ctx.strokeStyle = "#000000";
         ctx.lineWidth = 1;
         ctx.moveTo(0, gridOptions.yaxis.to * gridHeight);
         ctx.lineTo(w, gridOptions.yaxis.to * gridHeight);   
         ctx.moveTo((0-gridOptions.xaxis.from) * gridWidth, 0);
         ctx.lineTo((0-gridOptions.xaxis.from) * gridWidth, h);  
         ctx.stroke();

         // Draw x and y labels.
         ctx.fillStyle = "#000000";
         ctx.font = "15px Arial";
         var txtW = ctx.measureText("x").width;
         ctx.fillText("x",w-txtW-5,(gridOptions.yaxis.to * gridHeight)+12);
         txtW = ctx.measureText("y").width;
         ctx.fillText("y", (0-gridOptions.xaxis.from*gridWidth)-txtW - 3, 10);

         // Draw scales.
         ctx.font = "13px Arial";
         ctx.fillStyle = "#000000";
         for(var x=gridOptions.xaxis.from; x<gridOptions.xaxis.to; x++) {
            var txtW = ctx.measureText(x+"").width;
            if(x!=0)ctx.fillText(x+"", (x-gridOptions.xaxis.from)*gridWidth - txtW/2.0,(gridOptions.yaxis.to * gridHeight)+12);
         }
         for(var y=gridOptions.yaxis.from; y<gridOptions.yaxis.to; y++) {
            var txtW = ctx.measureText(y+"").width;
            // Possible to find text height? The 4 needs to be half of this.
            var toDraw = y!=0 && (gridHeight >= 15 || y%2==0);
            if(toDraw)ctx.fillText(y+"", (0-gridOptions.xaxis.from*gridWidth)-txtW - 3, 4 + h - ((y-gridOptions.yaxis.from) * gridHeight));
         }
      }
      else if(gridOptions.mode=="graph") {

         // Work out number of grids on each axis.
         var xDiff = gridOptions.xaxis.to - gridOptions.xaxis.from;
         var yDiff = gridOptions.yaxis.to - gridOptions.yaxis.from;

         var xValPerGrid = roundToNearestNiceNumber(xDiff / xGraphGrids);
         var yValPerGrid = roundToNearestNiceNumber(yDiff / yGraphGrids);
         // console.log("XVALPERGRID: "+(xDiff / xGraphGrids)+" ROUNDED: "+xValPerGrid);

         // Draw grid lines.
         ctx.beginPath(); 
         ctx.strokeStyle = gridOptions.color ? gridOptions.color : "#bbbbbb";
         ctx.lineWidth = 1;
         var isCategorical = gridOptions.xaxis.values!=undefined && $.trim(gridOptions.xaxis.values)!="";
         var cvalues;
         if(isCategorical)cvalues = gridOptions.xaxis.values.split(",");
         var numX = isCategorical ? cvalues.length : Math.ceil(xDiff / xValPerGrid);
         var numY = Math.ceil(yDiff / yValPerGrid);
         var gridWidth = (w-xGraphMarginRight*w-xGraphMarginLeft*w)/numX;
         var gridHeight = (h-yGraphMarginTop*h-yGraphMarginBottom*h)/numY;
         if(isCategorical) {
            for(var i=0; i<cvalues.length; i++) {
               var xstart = xGraphMarginLeft*w + gridWidth*i;
               ctx.beginPath();
               ctx.lineWidth = 2;
               ctx.moveTo(xstart + gridWidth*0.2, h-(yGraphMarginBottom+0.05)*h);
               ctx.lineTo(xstart + gridWidth*0.2, h-yGraphMarginBottom*h);
               ctx.moveTo(xstart + gridWidth*0.8, h-(yGraphMarginBottom+0.05)*h);
               ctx.lineTo(xstart + gridWidth*0.8, h-yGraphMarginBottom*h);
               ctx.stroke();
               ctx.lineWidth = 1;
            }
         } else {
            var i=xGraphMarginLeft*w; while(i<w-xGraphMarginRight*w + 2) { // vertical lines
               ctx.moveTo(i, yGraphMarginTop*h);
               ctx.lineTo(i, h-yGraphMarginBottom*h);
               i+= gridWidth;
            }
         }

         var i=h-yGraphMarginBottom*h; while(i>=yGraphMarginTop*h - 2) { // horizontal lines
            ctx.moveTo(xGraphMarginLeft*w, i);
            ctx.lineTo(w-xGraphMarginRight*w, i);
            i-= gridHeight;
         }    
         ctx.stroke();

         // Draw axis.
         ctx.beginPath(); 
         ctx.strokeStyle = "#000000";
         ctx.lineWidth = 1;
         ctx.moveTo(xGraphMarginLeft*w, yGraphMarginTop*h);
         ctx.lineTo(xGraphMarginLeft*w, h - yGraphMarginBottom*h);   
         ctx.moveTo(xGraphMarginLeft*w, h - yGraphMarginBottom*h);
         ctx.lineTo(w - xGraphMarginRight*w, h - yGraphMarginBottom*h);  
         ctx.stroke();

         // Draw scales.
         ctx.fillStyle = "#000000";
         ctx.font = (w>460 ? 13 : Math.round(15*w/460))+"px Arial";

         if(isCategorical) {
            for(var i=0; i<cvalues.length; i++) {
               var val = $.trim(cvalues[i]);
               var txtW = ctx.measureText(val).width;
               var xstart = xGraphMarginLeft*w + gridWidth*(i+0.5);
               ctx.fillText(val, xstart - txtW/2.0, h - yGraphMarginBottom*h + 13);
            }
         } else if(gridOptions.xaxis.type==="numeric") {
            var x=parseInt(gridOptions.xaxis.from); while(x<=parseFloat(gridOptions.xaxis.to)) {
               x = cleanUpFloat(x);
               var txtW = ctx.measureText(x+"").width;
               ctx.fillText(x+"", ((x-gridOptions.xaxis.from)/xValPerGrid)*gridWidth + xGraphMarginLeft*w - txtW/2.0, h - yGraphMarginBottom*h + 13);
               x = x + xValPerGrid;
            }
         }
         if(gridOptions.yaxis.type!=="none") {
            var y=parseInt(gridOptions.yaxis.from); while(y<=parseFloat(gridOptions.yaxis.to)) {
               y = cleanUpFloat(y);
               var txtW = ctx.measureText(y+"").width;
               ctx.fillText(y+"", xGraphMarginLeft*w - txtW - 4, h - yGraphMarginBottom*h - ((y-gridOptions.yaxis.from)/yValPerGrid)*gridHeight + 5 );
               y = y + yValPerGrid;
            }
         }

         // Axis label
         ctx.fillStyle = "#000000";
         // ctx.font = "15px Arial";
         var xL = gridOptions.xaxis.label;
         var yL = gridOptions.yaxis.label;
         var txtW = ctx.measureText(xL).width;
         ctx.fillText(xL,(w/2)-(txtW/2),h-6);
         ctx.save();
         txtW = ctx.measureText(yL).width;
         ctx.translate(12, h/2.0);
         ctx.rotate(-Math.PI/2);
         ctx.fillText(yL, 0-txtW/2.0, 0);
         ctx.restore();
      }
   }

   if(canvas.data("nonAnswerShapes"))$.each(canvas.data("nonAnswerShapes"), function(k,v){
      if(v.type=="polygon"){
         dfmCanvasDrawShape(v, canvas, k);
         var totalX = 0.0;
         var totalY = 0.0;
         for(var i=0; i<v.points.length-1; i++) {
            totalX+= v.points[i].x;
            totalY+= v.points[i].y;
         };
         var centreX = totalX / (v.points.length-1);
         var centreY = totalY / (v.points.length-1);
         ctx.fillStyle = "#eee";
         ctx.font = "19px Arial";
         var letter = String.fromCharCode("A".charCodeAt(0) + k);
         var txtW = ctx.measureText(letter).width;
         var centreNew = mapCoordinateToPixel({x: centreX, y: centreY}, canvas.data("grid"));
         ctx.fillText(letter, centreNew.x*w - txtW/2.0, centreNew.y*h);
      } else {
         dfmCanvasDrawShape(v, canvas, k);
      }
   });
   if(canvas.data("answerShape"))dfmCanvasDrawShape(canvas.data("answerShape"), canvas, -1);
   if(canvas.data("tempShape"))dfmCanvasDrawShape(canvas.data("tempShape"), canvas, -1);

}

function cleanUpFloat(num) {
   return (Math.round((num)*1000))/1000;
}


var tolerance = 10;
var pointWidth = 5;
var nonAnswerColors = ['rgb(112,172,71)','rgb(91,154,213)','rgb(253,191,0)','rgb(237,125,50)'];
function arePointsClose(p1, p2, canvas) {
   var diffX = Math.abs((p1.x - p2.x)*canvas.width());
   var diffY = Math.abs((p1.y - p2.y)*canvas.height());
   return diffX <= 15 && diffY <= 15;
}

function isInsidePolygon(point, vs) {
    var x = point.x, y = point.y;
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i].x, yi = vs[i].y;
        var xj = vs[j].x, yj = vs[j].y;
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};


function roundToNearestNiceNumber(num) {
   var pow = Math.floor(Math.log(num) / Math.log(10));
   var front = num / Math.pow(10,pow);
   var fixedNums = [1,2,5,10];
   var bestN = -1;
   var bestDist = 100000;
   for(var i=0; i<fixedNums.length; i++) {
      var dist = Math.abs(front - fixedNums[i]);
      if(dist >= 0 && dist < bestDist) {
         bestN = fixedNums[i];
         bestDist = dist;
      }
   }
   return bestN * Math.pow(10,pow);
}

function dfmCanvasDrawShape(shape, canvas, i) {
   var ctx = canvas[0].getContext("2d");
   var w = canvas.width();
   var h = canvas.height()
   var polygonPoints = shape.points;
   var tempPoint = shape.tempPoint; // may be undefined (applicable only to tempShape)
   
   ctx.lineCap = "round";
   ctx.lineWidth = 1;
   if(i==-1) {
      ctx.strokeStyle = "#000000";
      ctx.fillStyle = "#000000";
   } else if(shape.color) {
      ctx.strokeStyle = shape.color;
      ctx.fillStyle = shape.color;
   } else {
      ctx.strokeStyle = nonAnswerColors[i%nonAnswerColors.length];
      ctx.fillStyle = nonAnswerColors[i%nonAnswerColors.length];
   }

   if(polygonPoints.length==0)return;
   if(shape.type=="rectangle") {
      var point1 = mapCoordinateToPixel(polygonPoints[0], canvas.data('grid'));
      var point2 = mapCoordinateToPixel(polygonPoints.length==2 ? polygonPoints[1] : tempPoint, canvas.data('grid'));
      if(polygonPoints.length==1) {
         ctx.fillRect(point1.x*w - pointWidth/2.0, point1.y*h - pointWidth/2.0, pointWidth, pointWidth);
      }
      if(!point2)return;

      var rX = Math.min(point1.x, point2.x);
      var rY = Math.min(point1.y, point2.y);
      var rW = Math.abs(point2.x - point1.x);
      var rH = Math.abs(point2.y - point1.y);
      ctx.fillRect(rX*w, rY*h, rW*w, rH*h);
      ctx.strokeStyle = "#000000";
      ctx.fillStyle = "#000000";
      ctx.rect(rX*w, rY*h, rW*w, rH*h);
      ctx.stroke();
      return;
   }

   ctx.beginPath();
   var p0 = mapCoordinateToPixel(polygonPoints[0], canvas.data('grid'));
   ctx.moveTo(p0.x * w, p0.y * h);
   $.each(polygonPoints, function(key, val){
      val = mapCoordinateToPixel(val, canvas.data('grid'));
      var adjustedX = val.x * w;
      var adjustedY = val.y * h;
      var widthMultiplier = 1.0;
      if(shape.type=="point")widthMultiplier = 2.0;
      if(shape.nodots!==true)ctx.fillRect(adjustedX - pointWidth*widthMultiplier/2.0, adjustedY - pointWidth*widthMultiplier/2.0, pointWidth*widthMultiplier, pointWidth*widthMultiplier);
      ctx.lineTo(adjustedX, adjustedY);
   });
   if(tempPoint) {
      if(arePointsClose(tempPoint, polygonPoints[0], canvas))tempPoint = polygonPoints[0];
      var tempPoint2 = mapCoordinateToPixel(tempPoint, canvas.data('grid'));
      ctx.lineTo(tempPoint2.x * w, tempPoint2.y * h);

   }
   ctx.stroke();
   if(polygonPoints.length>=2 && (arePointsClose(polygonPoints[0], polygonPoints[polygonPoints.length-1], canvas) || (tempPoint && arePointsClose(polygonPoints[0], tempPoint, canvas)))) {
      ctx.closePath();
      if(shape.type=="polygon")ctx.fill();
   } 

}

function notifyCanvasListeners(canvas) {
   if(canvas.data("listeners")) {
      $.each(canvas.data("listeners"), function(k,v){
         v();
      });
   }
}


$(window).resize(function(){ 
   resizeCanvas() 
});

var canvasAspectRatio = 1.4;
function resizeCanvas(){
   $("canvas.dfmCanvas").each(function(){
      // console.log("ID: "+$(this).attr('id')+" CLASS: "+$(this).attr('class')+" READONLY: "+$(this).data("readOnly")+" ISDISPLAYED: "+$(this).is(":visible"));
      var canvas = $(this);
      if(canvas.is(":visible")){
         canvas.attr('width','1px');
         canvas.attr('height','1px');
         var newWidth = canvas.parent().width() - 20;
         // console.log("New width: "+newWidth);
         if(newWidth > 600)newWidth = 600;
         canvas.attr('width', newWidth+"px");
         var newHeight = newWidth / canvasAspectRatio;
         canvas.attr('height', newHeight+"px");
         if(canvas.data("redrawTimer"))clearTimeout(canvas.data("redrawTimer"));
         canvas.data("redrawTimer",setTimeout(function(){ canvas.dfmCanvas("redraw"); }, 100));
      }
   });

   $("canvas.dfmProgressLineGraph").each(function(){
      var canvas = $(this);
      if(canvas.is(":visible")){
         canvas.attr('width','1px');
         // canvas.attr('height','1px');
         var newWidth = canvas.parent().width() - 20;
         // console.log("New width: "+newWidth);
         if(newWidth > 600)newWidth = 600;
         canvas.attr('width', newWidth+"px");
         // var newHeight = newWidth / canvasAspectRatio;
         // canvas.attr('height', newHeight+"px");
         if(canvas.data("redrawTimer"))clearTimeout(canvas.data("redrawTimer"));
         canvas.data("redrawTimer",setTimeout(function(){ canvas.dfmProgressLineGraph("redraw"); }, 100));
      }
   });

}


function addShapeSelectorDialog(button, canvas){
   button.click(function(){

      var content = "Select a shape type.<br><br>";
      content+= "<input type='radio' name='shape-selector-type' value='point' id='sst-point'> <label for='sst-point'>Point</label><br/>"
            +"<input type='radio' name='shape-selector-type' value='line' id='sst-line'> <label for='sst-line'>Line</label><br/>"
            +"<input type='radio' name='shape-selector-type' value='multiline' id='sst-multiline'> <label for='sst-multiline'>Multi-Line</label><br/>"
            +"<input type='radio' name='shape-selector-type' value='polygon' id='sst-polygon'> <label for='sst-polygon'>Polygon</label><br/>"
            +"<input type='radio' name='shape-selector-type' value='rectangle' id='sst-rectangle'> <label for='sst-rectangle'>Rectangle</label><br><br>"
      dfmDialog(content, [{label: "Add", action: function(){
         var shape = $("input[name=shape-selector-type]:checked").val();
         canvas.dfmCanvas("startDrawing", shape);
      }}] );

   });
}

function addGridButtonDialog(button, canvas){
   button.click(function(){
       var input = "<style>.vex-custom-field-wrapper { margin: 1em 0; } "
           +"label.menu-inline {  display: inline-block; margin-bottom: .2em; } "
           +"input.menu-inline {  display: inline-block; width: 60px!important; } "
           +".tg { display: none; }"
           +"</style>"
         +"<div id='gridmenu' style='margin-top:10px'>"
         +"   <ul>"
         +"      <li><a href='#tabs-axis'>Coordinate Axes</a></li>"
         +"      <li><a href='#tabs-graph'>Graph</a></li>"
         +"   </ul>"
         +"   <div id='tabs-axis'>"
         +"      <div class='vex-custom-field-wrapper'>"
         +"         <label for='x-from'>Horizontal Axis</label>"
         +"         <div class='vex-custom-input-wrapper'>"
         +"            From: <input name='x-from' type='text' value='-7' class='menu-inline' style='width:50px'/> "
         +"            To: <input name='x-to' type='text' value='7' class='menu-inline' style='width:50px'/>  "
         +"         </div>"
         +"      </div>"
         +"      <div class='vex-custom-field-wrapper'>"
         +"         <label for='y-from'>Vertical Axis</label>"
         +"         <div class='vex-custom-input-wrapper'>"
         +"            From: <input name='y-from' type='text' value='-3' class='menu-inline' style='width:50px'/> "
         +"            To: <input name='y-to' type='text' value='6' class='menu-inline' style='width:50px'/>" 
         +"         </div>"
         +"      </div>"
         +"   </div>"
         +"   <div id='tabs-graph'>"
         +"      <div class='vex-custom-input-wrapper'>"
         +"         <hr/>"
         +"         <strong>Horizontal Axis</strong><br/>"
         +"         Axis Label: <input name='x-label-g' type='text'/> Type: <select name='x-gridtype'><option value='numeric'>Numeric</option><option value='categorical'>Categorical</option><option value='none'>Hide Values</option></select>"
         +"         <div id='tabs-graph-categorical' class='tg'>"
         +"            <label for='x-categoricalvalues'>Values (comma separated):</label> <input name='x-categoricalvalues' type='text'>"
         +"         </div>"
         +"         <div id='tabs-graph-numeric' class='tg'>"
         +"            From: <input name='x-from-g' type='text' value='0' class='menu-inline' style='width:50px'/> "
         +"            To: <input name='x-to-g' type='text' value='20' class='menu-inline' style='width:50px'/> "
         +"         </div>"
         +"         <hr/>"
         +"         <strong>Vertical Axis</strong><br/>"
         +"         Axis Label: <input name='y-label-g' type='text'/><br>"
         +"         Type: <select name='y-gridtype'><option value='numeric'>Numeric</option><option value='none'>Hide Values</option></select><br>"
         +"         From: <input name='y-from-g' type='text' value='0' class='menu-inline' style='width:50px'/> "
         +"         To: <input name='y-to-g' type='text' value='20' class='menu-inline' style='width:50px'/> "
         +"      </div>"
         +"   </div>"
         +"</div>";
       dfmDialog(input, [{label: "OK", action: function(){

            var data = {
               "x-from": $("input[name=x-from]").val(),
               "x-to": $("input[name=x-to]").val(),
               "y-from": $("input[name=y-from]").val(),
               "y-to": $("input[name=y-to]").val(),
               "x-label-g": $("input[name=x-label-g]").val(),
               "x-gridtype": $("select[name=x-gridtype]").val(),
               "x-from-g": $("input[name=x-from-g]").val(),
               "x-to-g": $("input[name=x-to-g]").val(),
               "y-label-g": $("input[name=y-label-g]").val(),
               "y-gridtype": $("select[name=y-gridtype]").val(),
               "y-from-g": $("input[name=y-from-g]").val(),
               "y-to-g": $("input[name=y-to-g]").val(),
               "x-categoricalvalues": $("input[name=x-categoricalvalues]").val()
            }
            var gridOptions = {};
            var mode = $("#gridmenu li").first().attr('class')==="selected" ? "axis" : "graph";
            gridOptions.xaxis = {};
            gridOptions.yaxis = {};
            gridOptions.mode = mode;
            if(mode=="axis") {
               gridOptions.xaxis.from = data['x-from'];
               gridOptions.xaxis.to = data['x-to'];
               gridOptions.yaxis.from = data['y-from'];
               gridOptions.yaxis.to = data['y-to'];
            } else {
               gridOptions.xaxis.label = data['x-label-g'];
               gridOptions.xaxis.type  = data['x-gridtype'];
               if(gridOptions.xaxis.type==="categorical")gridOptions.xaxis.values = data['x-categoricalvalues'];
               else if(gridOptions.xaxis.type==="numeric") {
                  gridOptions.xaxis.from = data['x-from-g'];
                  gridOptions.xaxis.to = data['x-to-g'];
               }
               gridOptions.yaxis.type = data['y-gridtype'];
               gridOptions.yaxis.label = data['y-label-g'];
               gridOptions.yaxis.from = data['y-from-g'];
               gridOptions.yaxis.to = data['y-to-g'];
            }
            canvas.dfmCanvas("setGrid", gridOptions);

       }}]);

       $("#gridmenu").dfmTabs();
       $("select[name='x-gridtype']").change(function(){
          $(".tg").hide();
          $("#tabs-graph-"+$("select[name='x-gridtype']").val()).show();
       });
       $("select[name='x-gridtype']").change();


   });
}

