import { posthogCaptureWithUserContext } from "./general";
import { getTaskPropertiesForEvent } from "./properties";

/**
 * Captures the event the user opens the "Set a Task" modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventSetATaskModalOpened({ triggerId }) {
  posthogCaptureWithUserContext("set_a_task_modal_opened", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user opens the "Start a Practice" modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventStartAPracticeModalOpened({ triggerId }) {
  posthogCaptureWithUserContext("start_a_practice_modal_opened", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user opens the "Learn" modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventLearnModalOpened({ triggerId }) {
  posthogCaptureWithUserContext("learn_modal_opened", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user closes the "Set a Task" modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventSetATaskModalClosed({ triggerId }) {
  posthogCaptureWithUserContext("set_a_task_modal_closed", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user closes the "Start a Practice" modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventStartAPracticeModalClosed({ triggerId }) {
  posthogCaptureWithUserContext("start_a_practice_modal_closed", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user closes the "Learn" modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventLearnModalClosed({ triggerId }) {
  posthogCaptureWithUserContext("learn_modal_closed", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user chooses "By Topic" from one of the
 * modals and is taken to the explorer page.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventTaskByTopicChosen({ triggerId }) {
  posthogCaptureWithUserContext("task_by_topic_chosen", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user chooses "Choose questions" from one
 * of the modals and is taken to the worksheets page.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventTaskChooseQuestionsChosen({ triggerId }) {
  posthogCaptureWithUserContext("task_choose_questions_chosen", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user is taken to the worksheets page after
 * choosing to use fixed questions.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.numberOfSubskills The number of subskills the user has selected.
 * @param {number} properties.numberOfQuestionsPerSubskill The number of questions the user has selected for each subskill.
 * @param {boolean} properties.interleave Whether the user has chosen to interleave questions.
 */
export function posthogEventTaskFixedQuestionsChosen({ triggerId, numberOfSubskills, numberOfQuestionsPerSubskill, interleave }) {
  posthogCaptureWithUserContext("task_fixed_questions_chosen", {
    trigger_id: triggerId,
    number_of_subskills: numberOfSubskills,
    number_of_questions_per_subskill: numberOfQuestionsPerSubskill,
    interleave,
  });
}

/**
 * Captures the event the user starts an independent practice task
 * configured themselves.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getTaskPropertiesForEvent>[0]} properties.task The task object.
 * @param {{ examQuestionSource: string | string[] | undefined } | undefined} properties.additionalInformation
 */
export function posthogEventTaskIndependentPracticeStarted({ triggerId, task, additionalInformation = {} }) {
  posthogCaptureWithUserContext("task_independent_practice_started", {
    trigger_id: triggerId,
    ...getTaskPropertiesForEvent(task, additionalInformation),
  });
}

/**
 * Captures the event the user configures and sets a task to an
 * individual or group of recipients.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getTaskPropertiesForEvent>[0]} properties.task The task object.
 * @param {Parameters<typeof getTaskPropertiesForEvent>[1] | undefined} properties.additionalInformation
 */
export function posthogEventTaskSet({ triggerId, task, additionalInformation }) {
  posthogCaptureWithUserContext("task_set", {
    trigger_id: triggerId,
    ...getTaskPropertiesForEvent(task, additionalInformation),
  });
}
