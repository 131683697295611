import { posthogCaptureWithUserContext } from "./general";
import {
  getBrowseCoursesFilterPropertiesForEvent,
  getCourseModulePropertiesForEvent,
  getCoursePropertiesForEvent,
  getCourseUnitPropertiesForEvent,
} from "./properties";

/**
 * Captures the event the user clicks the opt-in banner link and opens the new courses page.
 *
 * @param {{ linkUrl: string }} properties
 */
export function posthogEventCoursesOptInBannerClicked({ linkUrl }) {
  posthogCaptureWithUserContext("courses_opt_in_banner_clicked", {
    link_url: linkUrl,
  });
}

/**
 * Captures the event the opt-in banner is closed without opting in.
 */
export function posthogEventCoursesOptInBannerClosed() {
  posthogCaptureWithUserContext("courses_opt_in_banner_closed");
}

/**
 * Captures the event the user clicks the opt-out banner link and reverts to the old courses page.
 *
 * @param {{ linkUrl: string }} properties
 */
export function posthogEventCoursesOptOutBannerClicked({ linkUrl }) {
  posthogCaptureWithUserContext("courses_opt_out_banner_clicked", {
    link_url: linkUrl,
  });
}

/**
 * Captures the event the opt-out banner is closed without opting out.
 */
export function posthogEventCoursesOptOutBannerClosed() {
  posthogCaptureWithUserContext("courses_opt_out_banner_closed");
}

/**
 * Captures the event the user indicates they want to manage their courses.
 *
 * @param {{ linkUrl: string, triggerId: string }} properties
 */
export function posthogEventCoursesManageClicked({ linkUrl, triggerId }) {
  posthogCaptureWithUserContext("courses_manage_clicked", {
    link_url: linkUrl,
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user indicates they want to browse or use a published course.
 *
 * @param {{ triggerId: string }} properties
 */
export function posthogEventCoursesBrowseClicked({ triggerId }) {
  posthogCaptureWithUserContext("courses_browse_clicked", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user views the details of a course.
 *
 * @param {{
 *  authorName: string,
 *  course: { coid: number, name: string, type: string },
 *  courseIsPremade: boolean,
 *  triggerId: string,
 *  browseCoursesContext: {
 *    count: number,
 *    filters: { text: string, publisher: string[], type: string[] },
 *    positionSelected: number
 *  } | undefined
 * }} properties
 */
export function posthogEventCourseViewed({
  authorName,
  course = {},
  courseIsPremade,
  triggerId,
  browseCoursesContext,
}) {
  const additionalContextProperties = browseCoursesContext
    ? {
        ...getBrowseCoursesFilterPropertiesForEvent(
          browseCoursesContext.count,
          browseCoursesContext.filters,
        ),
        browse_courses_list_position: browseCoursesContext.positionSelected,
      }
    : {};

  posthogCaptureWithUserContext("course_viewed", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent({ ...course, authorName, courseIsPremade }),
    ...additionalContextProperties,
  });
}

/**
 * Captures the event the user views a course unit on the explorer.
 *
 * @param {{
 *  authorName: string,
 *  course: { coid: number, name: string, type: string },
 *  courseIsPremade: boolean,
 *  module: { cmid: number, name: string, o: number },
 *  triggerId: string,
 *  unit: { cuid: number, name: string, o: number }
 * }} properties
 */
export function posthogEventCourseUnitViewed({
  authorName,
  course = {},
  courseIsPremade,
  module = {},
  triggerId,
  unit = {},
}) {
  posthogCaptureWithUserContext("course_unit_viewed", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent({ ...course, authorName, courseIsPremade }),
    ...getCourseModulePropertiesForEvent(module),
    ...getCourseUnitPropertiesForEvent(unit),
  });
}

/**
 * Captures the event the user adds a premade course to their school
 * allocation.
 *
 * @param {{
 *  authorName: string,
 *  course: { coid: number, name: string, type: string },
 *  triggerId: string
 * }} properties
 */
export function posthogEventPremadeCourseAdded({
  authorName,
  course = {},
  triggerId,
}) {
  posthogCaptureWithUserContext("premade_course_added", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent({ ...course, authorName, courseIsPremade: true }),
  });
}

/**
 * Captures the event the user removes a premade course from their
 * school allocation.
 *
 * @param {{
 *  authorName: string,
 *  course: { coid: number, name: string, type: string },
 *  triggerId: string
 * }} properties
 */
export function posthogEventPremadeCourseRemoved({
  authorName,
  course = {},
  triggerId,
}) {
  posthogCaptureWithUserContext("premade_course_removed", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent({ ...course, authorName, courseIsPremade: true }),
  });
}

/**
 * Captures the event the user copies a premade course to their
 * own school as a draft.
 *
 * @param {{
 *  authorName: string,
 *  copiedCourseId: number,
 *  course: { coid: number, name: string, type: string },
 *  triggerId: string
 * }} properties
 */
export function posthogEventPremadeCourseCopied({
  authorName,
  copiedCourseId,
  course = {},
  triggerId,
}) {
  posthogCaptureWithUserContext("premade_course_copied", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent({ ...course, authorName, courseIsPremade: true }),
    copied_course_id: copiedCourseId,
  });
}

/**
 * Captures the event the user leaves the browse courses page
 * without selecting a course.
 *
 * @param {{
 *  count: number,
 *  filters: { text: string, publisher: string[], type: string[] }
 * }} properties
 */
export function posthogEventBrowseCoursesLeftWithoutSelection({ count, filters = {} }) {
  posthogCaptureWithUserContext("browse_courses_left_without_selection", {
    ...getBrowseCoursesFilterPropertiesForEvent(count, filters),
  });
}