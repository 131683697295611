import { Context } from "~/public_html/dfm5";

/**
 * Checks if a feature flag is present and enabled in the Context object.
 *
 * @param {string} name
 * @returns {boolean}
 */
export function isFeatureFlagEnabled(name) {
  const flags = Context?.featureFlags ?? [];
  return flags.some((flag) => flag.name === name && flag.enabled);
}

/**
 * Enables a feature flag for the current user or session.
 *
 * @param {string} name The name of the feature flag to enable.
 * @returns {Promise<boolean>} True if the feature flag was enabled successfully.
 */
export async function enableFeatureFlag(name) {
  try {
    const response = await fetch(`/api/user/current/featureflag/enable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    });
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    return true;
  } catch (e) {
    console.log(e.message);
    return false;
  }
}

/**
 * Disables a feature flag for the current user or session.
 *
 * @param {string} name The name of the feature flag to disable.
 * @returns {Promise<boolean>} True if the feature flag was disabled successfully.
 */
export async function disableFeatureFlag(name) {
  try {
    const response = await fetch(`/api/user/current/featureflag/disable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    });
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    return true;
  } catch (e) {
    console.log(e.message);
    return false;
  }
}
