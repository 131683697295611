import posthog from "posthog-js";

/**
 * Initialises the local posthog object
 */
export function posthogInit() {
  const posthogKey = window.DFL?.posthogKey;
  const posthogUrl = window.DFL?.posthogUrl;
  if (posthogKey && posthogUrl) {
    posthog.init(posthogKey,
      {
        advanced_disable_decide: true,
        api_host: posthogUrl,
        autocapture: false,
        capture_pageleave: false,
        capture_pageview: false,
        persistence: localStorage.getItem("cookie_consent") === "optional" ? "localStorage+cookie" : "memory",
        person_profiles: "identified_only",
      }
    );
  } else {
    console.log("PostHog could not be initialised as the key or URL is missing.");
  }
}

/**
 * Captures a simple pageview event without loading the user context.
 * (Which relies on loading the whole of DFM5.js, plus MathJax, plus tinymce).
 */
export function posthogPageviewEventBasic() {
  posthog.capture("$pageview", { referrer: document.referrer });
}

/**
 * Captures a simple click event without user context.
 */
export function posthogEventBasicLinkClick(event) {
  posthog.capture("link_clicked", {
    link_url: event.currentTarget.href,
    link_label: (event.currentTarget.innerText || event.target.alt)?.trim(),
  });
}

/**
 * Captures a video-play event without user context.
 */
export function posthogEventVideoStart(id, title) {
  posthog.capture("video_playback_started", { video_id: id, video_title: title });
}

/**
 * Captures video progress events without user context.
 */
export function posthogEventVideoProgress(id, title, percent) {
  posthog.capture("video_playback_progressed", { video_id: id, video_title: title, video_progress: percent });
}

/**
 * Captures a video-complete event without user context.
 */
export function posthogEventVideoComplete(id, title) {
  posthog.capture("video_playback_completed", { video_id: id, video_title: title });
}
